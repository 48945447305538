import React, { useContext, useEffect, useState } from "react"
import "../Home/Home.css"
import { LatestPostsType, PageInfoType, PageResultType } from "../../utils/types"
import { ContentSection } from "../Page/Page"
import styles from "../Page/Page.module.css"
import { useTranslation } from "react-i18next"
import { BLOGPOST_BASE_URL } from "../../utils/routesConfig"
import { PostsType } from "../../utils/types"
import { DisplayTopic } from "../ProjectsOverviewPage/ProjectsDisplayer"
import { useLocation, useNavigate } from "react-router"
import { fetchPostsPageQuery } from "../../utils/apiCalls"
import { Button, Icon } from "semantic-ui-react"
import { TranslationLinkToContext } from "../../components/TranslationLinkToContextProvider"

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

function Posts(): JSX.Element {
  const [isLoading, setLoading] = useState<boolean | string>()
  const [wpPage, setWPPage] = useState<PageResultType>()
  const [latestPosts, setLatestPosts] = useState<LatestPostsType & PageInfoType>()
  const { setLinkTo } = useContext(TranslationLinkToContext)
  const location = useLocation()
  const navigate = useNavigate()
  let query = useQuery()
  const after = query.get("after") || undefined
  const before = query.get("before") || undefined

  console.log(location)

  const { i18n } = useTranslation()

  useEffect(() => {
    if (isLoading !== true) {
      try {
        setLoading(true)
        fetchPostsPageQuery({ before, after }).then(
          (res: { page: { translation: any }; posts: any }) => {
            if (res) {
              setWPPage(res?.page?.translation)
              setLatestPosts(res?.posts)
              setLoading("DONE")
            } else {
              setLoading("error")
            }
          },
        )
      } catch (e) {
        // setLoading('error')
      }
      setLinkTo(i18n.language === "de" ? "/en/posts/" : "/posts/")
    }
    // return () => ac.abort()
    // }, [])
  }, [i18n.language, before, after])

  return isLoading === "error" ? (
    <></>
  ) : (
    <>
      <ContentSection pathname={"/"} wpPage={wpPage} />
      {Array.isArray(latestPosts?.nodes) && (
        <>
          <div className={styles.divider} />
          {/*<div className={styles.titleWrapper}>*/}
          {/*  {wpPage && <h1>{t("Aktuelle Nachrichten")}</h1>}*/}
          {/*</div>*/}
        </>
      )}
      {/*{isLoading === "DONE" && Array.isArray(latestPosts?.nodes) && (*/}
      {/*    <>{<BlogPostCarousell propPosts={latestPosts?.nodes.map((p) => p.translation)} />}</>*/}
      {/*)}*/}

      {Array.isArray(latestPosts?.nodes) &&
        latestPosts?.nodes.map((postObj: PostsType, index) => {
          const post = postObj?.translation
          return (
            <div key={post?.uri + index} onClick={() => navigate(BLOGPOST_BASE_URL + post?.uri)}>
              <DisplayTopic
                key={post.title}
                topic={{
                  content: post.excerpt,
                  title: post.title,
                  featuredImage: post?.featuredImage,
                }}
                date={post.date}
                onClick={() => navigate(BLOGPOST_BASE_URL + post?.uri)}
              />
            </div>
          )
        })}

      {
        <div>
          <Button
            circular
            disabled={!latestPosts?.pageInfo.hasPreviousPage}
            onClick={() => {
              navigate(location.pathname + "?before=" + latestPosts?.pageInfo.startCursor)
            }}
          >
            <Icon className={"angle double left"} />
          </Button>
          <Button
            circular
            disabled={!latestPosts?.pageInfo.hasNextPage}
            onClick={() => {
              navigate(location.pathname + "?after=" + latestPosts?.pageInfo.endCursor)
            }}
          >
            <Icon className={"angle double right"} />
          </Button>
        </div>
      }
    </>
  )
}

export default Posts
