import React, { useEffect, useState } from "react"
import { JobHistoryPost } from "../utils/types"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./BlogPostCarousell.css"
import styles from "./BlogPostCarousell.module.css"
import classNames from "classnames"
import { Button, Header, Icon } from "semantic-ui-react"
import { useNavigate } from "react-router"
import { BLOGPOST_BASE_URL } from "../utils/routesConfig"
import { useTranslation } from "react-i18next"

function BlogPostCarousell({
  category,
  propPosts,
  location,
}: {
  category?: string
  location?: string
  propPosts?: any[]
}) {
  const [posts, setPosts] = useState<JobHistoryPost[]>([])
  const [activeIndex, setActiveIndex] = useState(-1)

  const navigate = useNavigate()
  const { t } = useTranslation("projectOverview")

  useEffect(() => {
    // const ac = new AbortController()
    if (!propPosts) {
      try {
        // setLoading(true)
        // fetchJobHistoryPosts(category).then((res) => {
        //   if (res) {
        //     setPosts([...res])
        //     setLoading("DONE")
        //   } else {
        //     setLoading("error")
        //   }
        // })
      } catch (e) {
        // setLoading('error')
      }
    } else {
      setPosts(propPosts)
    }
    // return () => ac.abort()
  }, [propPosts, category])

  const responsive = {
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    bigDesktop: {
      breakpoint: { max: 4000, min: 2000 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 735 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 735, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  return (
    <>
      {posts.length === 0 ? null : posts.length === 0 ? null : ( //TODO add error message/component
        <>
          <Header style={{ textAlign: "center" }}>Aktuelles</Header>
          <div className={styles.carouselWrapper}>
            <div className={styles.carouselLine} />
            <Carousel
              responsive={responsive}
              // autoPlay={true}
              // autoPlaySpeed={8000}
              infinite={false}
              renderButtonGroupOutside
              partialVisible={false}
              containerClass={styles.carouselContainer}
            >
              {posts.map((post, index) => {
                // const tillDato = post?.history?.to === "NOW" ? "bis Dato" : post?.history?.to
                return (
                  <div
                    onClick={() => navigate(BLOGPOST_BASE_URL + post?.uri)}
                    key={index}
                    style={{ height: "33rem", marginLeft: "1rem", cursor: "pointer" }}
                  >
                    <div
                      className={classNames(styles.expandableWrapper, {
                        [styles.expandableWrapperExpanded]: activeIndex === index,
                      })}
                      style={activeIndex === index ? { maxHeight: "auto", height: "auto" } : {}}
                      onMouseEnter={() => setActiveIndex(activeIndex === index ? -1 : index)}
                      onMouseLeave={() => setActiveIndex(activeIndex === index ? -1 : index)}
                    >
                      <div
                        className={styles.cardImageWrapper}
                        style={{
                          backgroundImage: post?.featuredImage?.node?.sourceUrl
                            ? `linear-gradient(to bottom, rgba(249, 243, 225, 0.95) 10%, rgba(0, 0, 0, 0) 40%),url("${post?.featuredImage?.node?.sourceUrl}")`
                            : 'url("https://api.rootsforlife.info/wp-content/uploads/2022/03/DE_SC_20211223_120000.jpeg)")', //TODO change this
                        }}
                      >
                        <div className={styles.flexInfo}>
                          <div className={styles.timePeriod}>{post?.date.split("T")[0]}</div>
                          {post?.categories?.nodes[0]?.name && (
                            <div className={styles.categories}>
                              {post?.categories?.nodes?.map((n) => (
                                <div>{n.name.split("-")[0]}</div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.title}>{post?.title}</div>
                      <div
                        className={
                          activeIndex === index ? styles.expandable : styles.expandableCollapsed
                        }
                      >
                        <p
                          className={
                            activeIndex === index
                              ? styles.expandableWrapperTextExpanded
                              : styles.expandableWrapperText
                          }
                        >
                          <div
                            className={styles.clampIt}
                            dangerouslySetInnerHTML={{ __html: post?.excerpt }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Carousel>
            <Button
              basic
              size={"large"}
              floated={"right"}
              onClick={() => {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
              }}
            >
              {t("toTopOfScreen")}
              <Icon className={styles.mLeft} name={"angle double up"} />
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default BlogPostCarousell
