import { FooterLinksType } from "../utils/types"
import styles from "./BaseLayout.module.css"
import { Link } from "react-router-dom"
import React from "react"
import { FacebookButton, LanguageButton } from "./BaseLayoutHeader"
import MediaQuery from "react-responsive"

export const BaseLayoutFooter = ({
  footerLinksObject,
}: {
  footerLinksObject: FooterLinksType[]
}) => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerLinks}>© Roots for life</div>
      <MediaQuery minWidth={750}>
        <div className={styles.footerLinks + " " + styles.rightSide}>
          {footerLinksObject.map((linkObj, index) => (
            <div key={index}>
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                }}
                to={linkObj.to}
              >
                {linkObj.text}
              </Link>
            </div>
          ))}
          <MediaQuery minWidth={1024}>
            <div className={styles.flagContainer + " " + styles.fbButtonContainer}>
              <FacebookButton />
            </div>

            <div className={styles.flagContainer}>
              <LanguageButton name={"us"} />
              <LanguageButton name={"de"} />
            </div>
          </MediaQuery>
        </div>
      </MediaQuery>
    </div>
  )
}
