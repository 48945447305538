export default {
  footerLinks: {
    "Kontakt & Impressum": "Kontakt & Impressum",
    "/kontakt-impressum": "/kontakt-impressum",
    Netzwerk: "Netzwerk",
    "/netzwerk": "/netzwerk",
    Downloads: "Newsletter & Downloads",
    "/downloads": "/downloads",
  },
  translation: {
    "Aktuelle Nachrichten": "Aktuelle Nachrichten",
  },
  api: {
    stuff: "dinge",
    DE: "DE",
    OTHER: "EN",
  },
  projectOverview: {
    all: "Alle",
    matrixClickUrl: "/projekte/",
    minimize: "...weniger lesen",
    readMore: "... mehr lesen",
    locations: "Orte",
    projectType: "Projektart",
    toTopOfScreen: "Zum Seitenanfang",
  },
}
