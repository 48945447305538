import React, { useRef } from "react"
import styles from "./BaseLayout.module.css"
import { BaseLayoutHeader } from "./BaseLayoutHeader"
import { BaseLayoutFooter } from "./BaseLayoutFooter"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

const BaseLayout = ({ children, ...props }: { children: any }) => {
  const { t } = useTranslation("footerLinks")
  const footerLinksObject = [
    { text: t("Kontakt & Impressum"), to: t("/kontakt-impressum") },
    // { text: t("Netzwerk"), to: t("/netzwerk") },
    { text: t("Downloads"), to: t("/downloads") },
  ]

  const mainContainerRef = useRef<HTMLDivElement>(null)
  return (
    <div className={classNames(styles.baseLayout, "page-background-color")}>
      <div className={styles.layoutDivider}>
        <BaseLayoutHeader
          footerLinksObject={footerLinksObject}
          mainContainerRef={mainContainerRef}
        />
        <div className={styles.mainContainer}>
          <div ref={mainContainerRef} className={styles.sideContainer} />
          <div className={styles.mainContentContainer}>{children}</div>
          <div className={styles.sideContainer} />
        </div>
      </div>
      <BaseLayoutFooter footerLinksObject={footerLinksObject} />
    </div>
  )
}

export default BaseLayout
