import React, { useContext, useEffect, useState } from "react"
import { PageResultType } from "../../utils/types"
import { useParams } from "react-router"
import { fetchPostQuery } from "../../utils/apiCalls"
import { ContentSection } from "../Page/Page"
import { TranslationLinkToContext } from "../../components/TranslationLinkToContextProvider"
import "./Blogpost.css"

function BlogPost() {
  const [isLoading, setLoading] = useState<boolean | string>()
  const [wpPage, setWPPage] = useState<PageResultType>()
  const { setLinkTo } = useContext(TranslationLinkToContext)
  const params = useParams()

  useEffect(() => {
    // const ac = new AbortController()
    try {
      setLoading(true)
      // setShowContent(false)
      fetchPostQuery(params.uri).then((res) => {
        if (res) {
          setWPPage(res)
          setLoading("DONE")
          setLinkTo(
            typeof res?.translation?.uri === "string"
              ? "/post/" + res?.translation?.uri
              : "/post/" + res.uri,
          )
          // setShowContent(true)
        } else {
          setLoading("error")
        }
      })
    } catch (e) {
      // setLoading('error')
    }
    // return () => ac.abort()
  }, [params, setLinkTo])

  return isLoading === "error" ? (
    <></>
  ) : (
    <div className={"blog-post"}>
      <ContentSection key={params.uri} pathname={params.uri} wpPage={wpPage} blogPost />
    </div>
  )
}

export default BlogPost
