import React, { useContext, useEffect, useState } from "react"
import { fetchPageQuery } from "../../utils/apiCalls"
import styles from "./Page.module.css"
import "./wpstyles.css"
import { PageResultType } from "../../utils/types"
import { useLocation } from "react-router"
import BlogPostCarousell from "../../components/BlogPostCarousell"
import { HeroImage } from "../../components/HeroImage"
import { TranslationLinkToContext } from "../../components/TranslationLinkToContextProvider"
import parse from "html-react-parser"
import { parseObj } from "../../utils/htmlParser"
let classNames = require("classnames")

function Page(): JSX.Element {
  const [isLoading, setLoading] = useState<boolean | string>()
  const [wpPage, setWPPage] = useState<PageResultType>()
  const { setLinkTo } = useContext(TranslationLinkToContext)
  const { pathname } = useLocation()

  useEffect(() => {
    // const ac = new AbortController()
    try {
      setLoading(true)
      // setShowContent(false)
      fetchPageQuery(pathname).then((res) => {
        if (res) {
          setWPPage(res)
          setLinkTo(typeof res?.translation?.uri === "string" ? res?.translation?.uri : pathname)
          setLoading("DONE")
          // setShowContent(true)
        } else {
          setLoading("error")
        }
      })
    } catch (e) {
      // setLoading('error')
    }
    // return () => ac.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return isLoading === "error" ? (
    <></>
  ) : (
    <ContentSection key={pathname} pathname={pathname} wpPage={wpPage} />
  )
}

export default Page

export const ContentSection = ({
  pathname,
  wpPage,
  children,
  isLoading,
  blogPost,
}: {
  pathname: string
  isLoading?: boolean | string | undefined
  blogPost?: boolean
  wpPage: PageResultType | undefined
  children?: any //TODO type this as optional
}) => {
  return (
    <div>
      <ContentFader
        pathname={pathname}
        wpPage={wpPage?.title}
        // key={typeof isLoading === "string" ? isLoading : ""}
      >
        {children ? (
          children
        ) : (
          <>
            <HeroImage wpPage={wpPage} />
            <div className={styles.titleWrapper}>{wpPage && <h1>{wpPage?.title}</h1>}</div>
            {blogPost && <p style={{ textAlign: "end" }}>{wpPage?.date?.split("T")[0]}</p>}
            <div>
              {wpPage &&
                (!blogPost ? (
                  <div
                    className={"pageContent"}
                    dangerouslySetInnerHTML={{
                      //@ts-ignore
                      __html: wpPage?.content,
                    }}
                  />
                ) : (
                  <div className={"pageContent"}>{parse(wpPage?.content, parseObj)}</div>
                ))}
            </div>
            {wpPage?.postCarousellCategory?.postCarousellCategory && (
              <>
                <div className={styles.divider} />
                <BlogPostCarousell
                  category={wpPage?.postCarousellCategory?.postCarousellCategory}
                />
              </>
            )}
          </>
        )}
      </ContentFader>
    </div>
  )
}

export const ContentFader = ({
  children,
  pathname,
  wpPage,
}: {
  children: any
  pathname: string
  wpPage: string | undefined
}) => {
  const [showContent, setShowContent] = useState<boolean>()

  useEffect(() => {
    setShowContent(true)
  }, [wpPage])
  const effectConst = pathname.split("/")[1]
  useEffect(() => {
    setShowContent(false)
  }, [effectConst])

  return <div className={classNames("contentFader", showContent ? "show" : "hide")}>{children}</div>
}
