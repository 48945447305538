import React, { useEffect, useState } from "react"
import { fetchCategoriesAndLocations, fetchPageQuery } from "../../utils/apiCalls"
import "../Home/Home.css"
import styles from "../Page/Page.module.css"
import { CategoryType, LocationType, PageResultType } from "../../utils/types"
import { ContentSection } from "../Page/Page"
import { useLocation, useNavigate } from "react-router"
import { ProjectsMatrix } from "./ProjectsDisplayer"
import { HeroImage } from "../../components/HeroImage"
import { useTranslation } from "react-i18next"

function ProjectsOverviewPage({ lang = "de" }): JSX.Element {
  const [isLoading, setLoading] = useState<boolean | string>()
  const [categories, setCategories] = useState<CategoryType[]>()
  const [locations, setLocations] = useState<LocationType[]>()
  const [wpPage, setWPPage] = useState<PageResultType>()
  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [i18n, lang])

  useEffect(() => {
    if (i18n.language === "en" && lang === "de") {
      navigate("../en/" + pathname)
    } else if (i18n.language === "de" && lang === "en") {
      navigate("../" + pathname.substring(3))
    }
  }, [i18n.language, navigate, pathname, lang])

  useEffect(() => {
    try {
      setLoading(true)
      fetchCategoriesAndLocations().then((res) => {
        //TODO change to id or something..
        if (res) {
          const locationsWihouthOther = res?.locations?.filter((r) => r.databaseId !== 267) //267 = database is for Other
          setLocations(locationsWihouthOther)
          setCategories(res?.categories)
          setLoading("DONE")
        } else {
          setLoading("error")
        }
      })
    } catch (e) {
      setLoading("error")
    }
    // return () => ac.abort()
  }, [i18n.language])

  useEffect(() => {
    let requestParams
    if (i18n.language === "de") {
      requestParams = pathname.split("/")[1]
    } else {
      requestParams = pathname.split("/")[1] + "/" + pathname.split("/")[2]
    }
    try {
      setLoading(true)
      fetchPageQuery(requestParams).then((res) => {
        //TODO change to id or something..
        if (res) {
          setWPPage(res)
          setLoading("DONE")
        } else {
          setLoading("error")
        }
      })
    } catch (e) {
      setLoading("error")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return isLoading === "error" ? (
    <></>
  ) : (
    <ContentSection isLoading={isLoading} pathname={pathname} wpPage={wpPage}>
      <HeroImage wpPage={wpPage} />
      <div className={styles.titleWrapper}>{wpPage && <h1>{wpPage?.title}</h1>}</div>
      <div style={{ marginBottom: "2rem" }}>
        {wpPage && <div dangerouslySetInnerHTML={{ __html: wpPage?.content }} />}
      </div>
      <ProjectsMatrix categories={categories} locations={locations} isLoading={isLoading} />
    </ContentSection>
  )
}

export default ProjectsOverviewPage
