export const BLOGPOST_BASE_URL = "/post"

export const config = {
  routes: {
    home: "/",
    posts: `/posts`,
    postsEn: `/en/posts`,
    page: "/:pageUri",
    pageEn: "/en/:pageUri",
    projectPage: "/projekte/:category/:location",
    projectPageCat: "/projekte/:category",
    projectPageBase: "/projekte",
    projectPageEn: "/en/projects/:category/:location",
    projectPageCatEn: "/en/projects/:category",
    projectPageBaseEn: "/en/projects",
    blogPost: `${BLOGPOST_BASE_URL}/:year/:month/:day/:uri`,
    blogPostEng: `${BLOGPOST_BASE_URL}/:language/:year/:month/:day/:uri`,
  },
} as const
