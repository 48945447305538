import { useEffect, useState } from "react"

const isMobileTreshold = 1200

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < isMobileTreshold)
  useEffect(() => {
    window.addEventListener("resize", () => setIsMobile(window.innerWidth < isMobileTreshold))

    return () => {
      window.removeEventListener("resize", () => setIsMobile(window.innerWidth < isMobileTreshold))
    }
  }, [])

  return isMobile
}
