//@ts-nocheck
import { attributesToProps, HTMLReactParserOptions } from "html-react-parser"
import React, { useState } from "react"

export const parseObj: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode.name === "img") {
      const props = attributesToProps(domNode.attribs)
      console.log(props)
      // const { className } = props
      return (
        <>
          <ImageWithModal {...props} />
        </>
      )
    } else {
      return
    }
  },
}

const ImageWithModal = ({ className, srcSet, src, ...props }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <img
        alt={""}
        className={className + " parsedImg"}
        {...props}
        srcSet={srcSet}
        // style={{ width: "100%", maxWidth: "300px" }}
        style={{
          display: "inline-block",
          maxHeight: "200px",
          maxWidth: "300px",
          width: "auto",
          height: "auto",
        }}
        onClick={() => {
          setShowModal(!showModal)
        }}
      />
      <div id="myModal" className={!showModal ? "modal" : "modal showParsed"}>
        <span
          className="close"
          onClick={() => {
            setShowModal(!showModal)
          }}
        >
          &times;
        </span>
        <img alt="" className="modal-content" srcSet={srcSet} />
        <div id="caption" />
      </div>
    </>
  )
}
