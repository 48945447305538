import {
  CategoryDescriptionType,
  CategoryType,
  LocationDescriptionType,
  LocationType,
} from "../../utils/types"
import React, { useEffect, useRef, useState } from "react"
import classnames from "classnames"
import { fetchCategoryDescriptions } from "../../utils/apiCalls"
import BlogPostCarousell from "../../components/BlogPostCarousell"
import { useNavigate } from "react-router-dom"
import ButtonGroup from "semantic-ui-react/dist/commonjs/elements/Button/ButtonGroup"
import { Button, Icon, Loader, Segment } from "semantic-ui-react"
import { useParams } from "react-router"
import styles from "./ProjectsOverViewPage.module.css"
import "./ProjectsDisplayer.css"
import { useIsMobile } from "../../utils/hooks"
import { useTranslation } from "react-i18next"

export const ProjectsMatrix = ({
  categories,
  locations,
  isLoading,
}: {
  categories?: CategoryType[]
  locations?: LocationType[]
  isLoading?: boolean | string | undefined
}) => {
  //TODO add useEffect that requests stuff based on location/

  const params = useParams()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const newsRef = useRef<null | HTMLDivElement>(null)
  const { t, i18n } = useTranslation()

  const [locationButtonClicked, setLocationButtonClicked] = useState<number | undefined>(undefined)
  const [categoryButtonClicked, setCategoryButtonClicked] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (categories?.length) {
      const indexOfParamCategory = categories.findIndex(
        (category, index) => category.name === params.category && true,
      )
      if (indexOfParamCategory !== undefined) setCategoryButtonClicked(indexOfParamCategory + 1)
    }
    if (locations?.length) {
      const indexOfParamLocation = locations.findIndex(
        (location, index) => location.name === params.location && true,
      )
      if (indexOfParamLocation !== undefined) setLocationButtonClicked(indexOfParamLocation + 1)
    }
  }, [params.location, params.category, categories, locations])

  const locationButtonClick = (index: number) => {
    const categoryAddr = categoryButtonClicked
      ? categories && categories[categoryButtonClicked - 1].name + "/"
      : "all/"

    const locationAddr =
      index + 1 === locationButtonClicked ? "" : locations && locations[index].name

    navigate(t("projectOverview:matrixClickUrl") + categoryAddr + locationAddr)
    index + 1 === locationButtonClicked
      ? setLocationButtonClicked(undefined)
      : setLocationButtonClicked(index + 1)
  }

  const categoryButtonClick = (index: number) => {
    const locationAddr = locationButtonClicked
      ? locations && locations[locationButtonClicked - 1].name + "/"
      : ""

    const categoryAddr =
      index + 1 === categoryButtonClicked ? "all/" : categories && categories[index].name
    navigate(t("projectOverview:matrixClickUrl") + categoryAddr + "/" + locationAddr)
    index + 1 === categoryButtonClicked
      ? setCategoryButtonClicked(undefined)
      : setCategoryButtonClicked(index + 1)
  }

  const sortOrderCat = {
    en: ["Cooperative-EN", "Farming-EN", "Income-EN", "Scholarship-EN"],
    de: ["Kooperative-DE", "Landwirtschaft-DE", "Einkommen-DE", "Stipendien-DE"],
  }

  const sortOrderLoc = {
    en: [
      "Chyangsar-EN",
      "Ghunsa-EN",
      "Kunikhop-EN",
      "Meranding-EN",
      "Kathmandu-EN",
      "Phokara-EN",
      "Other-location-EN",
    ],
    de: [
      "Chyangsar-DE",
      "Ghunsa-DE",
      "Kunikhop-DE",
      "Meranding-DE",
      "Kathmandu-DE",
      "Phokara-DE",
      "Sonstige-DE",
    ],
  }

  const sortCatFunc = (c: CategoryType, c2: CategoryType) => {
    return (
      // @ts-ignore
      sortOrderCat[i18n.language].indexOf(c.name) - sortOrderCat[i18n.language].indexOf(c2.name)
    )
  }

  const sortLocFunc = (l: LocationType, l2: LocationType) => {
    return (
      // @ts-ignore
      sortOrderLoc[i18n.language].indexOf(l.name) - sortOrderLoc[i18n.language].indexOf(l2.name)
    )
  }

  return (
    <>
      {isMobile ? (
        <>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div className={"mobile-button-section"}>
              <p style={{ textAlign: "center" }}>{t("projectOverview:locations")}</p>
              <ButtonGroup size={"big"} basic vertical>
                <Button
                  key={"allButton"}
                  active={!locationButtonClicked}
                  // disabled={!locationButtonClicked}
                  onClick={() => {
                    locationButtonClicked && locationButtonClick(locationButtonClicked - 1)
                  }}
                >
                  {t("projectOverview:all")}
                </Button>
                {locations &&
                  locations.sort(sortLocFunc).map((location, index) => {
                    //TODO remove need for the split in btn name
                    return (
                      <Button
                        className={classnames(
                          locationButtonClicked &&
                            index + 1 === locationButtonClicked &&
                            styles.allbuttonSelected,
                        )}
                        key={index}
                        active={index + 1 === locationButtonClicked || !locationButtonClicked}
                        onClick={() => locationButtonClick(index)}
                      >
                        {location.name.split("-")[0]}
                      </Button>
                    )
                  })}
              </ButtonGroup>
            </div>
            <div className={"mobile-button-section"}>
              <p style={{ textAlign: "center" }}>{t("projectOverview:projectType")}</p>
              <ButtonGroup size={"big"} basic vertical>
                <Button
                  key={"allButton"}
                  active={!categoryButtonClicked}
                  // disabled={!categoryButtonClicked}
                  onClick={() => {
                    categoryButtonClicked && categoryButtonClick(categoryButtonClicked - 1)
                  }}
                >
                  {t("projectOverview:all")}
                </Button>
                {categories &&
                  categories.sort(sortCatFunc).map((category, index) => {
                    return (
                      <Button
                        className={classnames(
                          categoryButtonClicked &&
                            index + 1 === categoryButtonClicked &&
                            styles.allbuttonSelected,
                        )}
                        key={index}
                        active={index + 1 === categoryButtonClicked || !categoryButtonClicked}
                        onClick={() => categoryButtonClick(index)}
                      >
                        {category.name.split("-")[0]}
                      </Button>
                    )
                  })}
              </ButtonGroup>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={"not-mobile-button-section"}>
            <p style={{ textAlign: "center" }}>{/*<h3>{"Projektart"}</h3>*/}</p>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
              <ButtonGroup style={{ width: "max-content" }} size={"big"} basic fluid>
                {categories &&
                  categories.sort(sortCatFunc).map((category, index) => {
                    return (
                      <Button
                        className={classnames(
                          categoryButtonClicked &&
                            index + 1 === categoryButtonClicked &&
                            styles.allbuttonSelected,
                        )}
                        key={index}
                        active={index + 1 === categoryButtonClicked || !categoryButtonClicked}
                        onClick={() => categoryButtonClick(index)}
                      >
                        {category.name.split("-")[0]}
                      </Button>
                    )
                  })}
                <Button
                  className={classnames(!categoryButtonClicked && styles.allbuttonSelected)}
                  key={"allButton"}
                  active={!categoryButtonClicked}
                  // disabled={!categoryButtonClicked}
                  onClick={() => {
                    categoryButtonClicked && categoryButtonClick(categoryButtonClicked - 1)
                  }}
                >
                  {t("projectOverview:all")}
                </Button>
              </ButtonGroup>

              <Button
                disabled={!locationButtonClicked}
                size={"big"}
                basic
                onClick={() => newsRef?.current?.scrollIntoView()}
              >
                {t("To news")} <Icon className={styles.mLeft} name={"angle double down"} />
              </Button>
            </div>
          </div>
          <div
            className={"not-mobile-button-section"}
            style={{ marginLeft: "-20%", marginTop: "1rem", float: "left" }}
          >
            {/*<p>*/}
            <h3 style={{ textAlign: "center" }}>{t("projectOverview:locations")}</h3>
            {/*</p>*/}

            <ButtonGroup size={"big"} basic vertical>
              {locations &&
                locations.sort(sortLocFunc).map((location, index) => {
                  //TODO remove need for the split in btn name
                  return (
                    <Button
                      className={classnames(
                        locationButtonClicked &&
                          index + 1 === locationButtonClicked &&
                          styles.allbuttonSelected,
                      )}
                      key={index}
                      active={index + 1 === locationButtonClicked || !locationButtonClicked}
                      onClick={() => locationButtonClick(index)}
                    >
                      {location.name.split("-")[0]}
                    </Button>
                  )
                })}
              <Button
                className={classnames(!locationButtonClicked && styles.allbuttonSelected)}
                key={"allButton"}
                active={!locationButtonClicked}
                // disabled={!locationButtonClicked}
                onClick={() => {
                  locationButtonClicked && locationButtonClick(locationButtonClicked - 1)
                }}
              >
                {t("projectOverview:all")}
              </Button>
            </ButtonGroup>
          </div>
        </>
      )}
      <div>
        {
          <>
            <div
              className={classnames(styles.loaderWrapper, isLoading === true && styles.isLoading)}
            >
              <Loader active size="massive" content="" />
            </div>
            {isLoading !== true && (
              <CategoryDisplayer
                categories={
                  categoryButtonClicked && categories
                    ? [categories[categoryButtonClicked - 1]]
                    : categories
                }
                newsRef={newsRef}
                location={
                  locationButtonClicked && locations
                    ? locations[locationButtonClicked - 1]
                    : undefined
                }
              />
            )}
          </>
        }
      </div>
    </>
  )
}
const CategoryDisplayer = ({
  categories,
  location,
  newsRef,
}: {
  categories?: CategoryType[]
  location?: LocationType
  newsRef?: React.RefObject<HTMLDivElement>
}) => {
  const [locationDescriptions, setlocationDescriptions] = useState<
    LocationDescriptionType[] | undefined
  >(undefined)
  const [categoryDescriptions, setCategoryDescriptions] = useState<
    CategoryDescriptionType[] | undefined
  >(undefined)
  const [posts, setPosts] = useState<any[] | undefined>(undefined)
  const [loading, setLoading] = useState<string | boolean>(false)

  useEffect(() => {
    try {
      if (categories !== undefined) {
        setLoading(true)
        fetchCategoryDescriptions(categories, location).then((res) => {
          //TODO change to id or something..
          if (res) {
            setlocationDescriptions(res.locationDescriptions)
            setCategoryDescriptions(res.categoryDescriptions)
            setPosts(res.posts)
            setLoading("DONE")
          } else {
            setLoading("error")
          }
        })
      }
    } catch (e) {
      setLoading("error")
    }
  }, [location, categories])

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          {locationDescriptions?.map((loc, index) => (
            <DisplayTopic key={index} topic={loc} onClick={() => {}} />
            //TODO make this link to smth
          ))}
        </div>
        <div>
          {categoryDescriptions?.reverse().map((cat, index) => (
            <DisplayTopic key={index} topic={cat} onClick={() => {}} />
            //TODO make this link to smth
          ))}
        </div>
        <div>
          {posts && loading === "DONE" && (
            <div ref={newsRef}>
              <BlogPostCarousell key={location?.name} propPosts={posts} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export const DisplayTopic = ({
  topic,
  onClick,
  date,
}: {
  topic: LocationDescriptionType
  onClick: () => void
  date?: string
}) => {
  const [expand, setExpand] = useState(false)
  const { t } = useTranslation("projectOverview")
  return (
    <>
      <Segment
        raised
        style={{
          backgroundColor: "rgb(220 187 23 / 20%)",
          fontSize: "16px",
          marginBottom: "1rem",
          minWidth: "min(440px, 90vw)",
          cursor: "pointer",
        }}
      >
        <div className={styles.projectDisplayContainer}>
          <div className={styles.imageWrapper}>
            <div
              style={{
                width: "100%",
                maxHeight: "390px",
                minHeight: "300px",
                minWidth: "min(440px, calc( 90vw - 2em ))",
                backgroundImage: `url("${topic?.featuredImage?.node?.sourceUrl})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                boxShadow: "0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%)",
              }}
            />
          </div>
          <div className={styles.contentWrapper}>
            <div>
              <h3 style={{ float: "left" }} onClick={onClick}>
                {topic.title}
              </h3>
              <div className={styles.date}>{date?.split("T")[0]}</div>
            </div>
            <div className={classnames(styles.expandable, expand && styles.expanded)}>
              <div style={{}} dangerouslySetInnerHTML={{ __html: topic.content }} />
            </div>
            {/*{topic.content.length > 600 && (*/}
            {true && (
              <div className={styles.expandableClickable}>
                <span onClick={() => setExpand(!expand)} className={styles.floatRight}>
                  {expand ? t("minimize") : t("readMore")}
                </span>
              </div>
            )}
          </div>
        </div>
      </Segment>
      {/*<div className={stylesPage.divider} />*/}
    </>
  )
}
