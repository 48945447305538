import { CategoryType, LocationType } from "./types"
import { request } from "graphql-request"
import { baseUrl, categoryIdArray, locationCategoryIdArray, staticContent } from "./apiCalls"
import i18next from "i18next"

const staticDbId = {
  DE: 277,
  EN: 279,
}

const postFragment = `fragment PostValues on Post {
  title
  content
  excerpt
  uri
  date
  databaseId
  featuredImage{
    node{
      sourceUrl
    }
  }
}`

export async function allLocationsOneCategory(categories: CategoryType[]) {
  const dbID = i18next.t("api:DE") === "DE" ? staticDbId.DE : staticDbId.EN
  const lng = i18next.language === "de" ? "de" : "en"
  let query = `{
      category(id: ${categories?.[0].databaseId}, idType: DATABASE_ID) {
        translation(language: ${i18next.t("api:DE")}) {
          name
          databaseId
          locationDescriptions: posts(where: {categoryIn: ${dbID}}) {
            nodes {
              ...PostValues
            }
          }

        categoryDescriptions: posts(where: {categoryIn: ${dbID}, categoryNotIn: [${
    locationCategoryIdArray[lng]
  }]}) {
          nodes {
            ...PostValues
          }
        }
       posts(first: 5, where: {categoryNotIn: ${dbID}}) {
          nodes {
           ...PostValues
          }
        }
      }
              }
    }
    ${postFragment}`

  const response = await request(baseUrl, query)
  //TODO remove location description from category description, and have it ass the location description
  return {
    posts: response.category?.posts?.nodes,
    locationDescriptions: response?.category?.translation?.locationDescriptions?.nodes,
    categoryDescriptions: response?.category?.translation?.categoryDescriptions?.nodes,
  }
}

export async function oneLocationsOneCategory(categories: CategoryType[], location: LocationType) {
  const lng = i18next.language === "de" ? "de" : "en"
  let exclusionArray = Array.from(categoryIdArray[lng])

  exclusionArray.splice(categoryIdArray[lng].indexOf(categories[0].databaseId), 1)

  const query = `{
  category(id: ${location.databaseId}, idType: DATABASE_ID) {
    name
    posts(where: {categoryId: ${categories[0].databaseId}, categoryNotIn: ${staticContent[lng].databaseId}}) {
      nodes {
         ...PostValues
      }
    }
    categoryDescriptions: posts(where: {categoryId:  ${staticContent[lng].databaseId}, categoryNotIn:[${exclusionArray}]}) {
      nodes {
         ...PostValues
      }
    }
    locationDescriptions: posts(where: {categoryId:  ${staticContent[lng].databaseId}, categoryNotIn: [${categoryIdArray[lng]}]}) {
      nodes {
        ...PostValues
      }
    }
  }
}${postFragment}`
  const response = await request(baseUrl, query)
  //TODO remove location description from category description, and have it ass the location description
  return {
    posts: response.category?.posts?.nodes,
    locationDescriptions: response?.category?.locationDescriptions?.nodes,
    categoryDescriptions: response?.category?.categoryDescriptions?.nodes,
  }
}

export async function oneLocationsAllCategories(
  categories: CategoryType[],
  location: LocationType,
) {
  const lng = i18next.language === "de" ? "de" : "en"
  const query = `{
  category(id: ${location.databaseId}, idType: DATABASE_ID) {
    posts(where: { categoryNotIn: ${staticContent[lng].databaseId}}) {
      nodes {
        ...PostValues
      }
    }
    categoryDescriptions: posts(where: {categoryId: ${staticContent[lng].databaseId}}) {
      nodes {
        ...PostValues
      }
    }
    locationDescriptions: posts(where: {categoryId: ${staticContent[lng].databaseId}, categoryNotIn: [${categoryIdArray[lng]}]}) {
      nodes {
         ...PostValues
      }
    }
  }
}${postFragment}`

  const response = await request(baseUrl, query)
  //TODO remove location description from category description, and have it as the location description
  return {
    posts: response.category?.posts?.nodes,
    locationDescriptions: response?.category?.locationDescriptions?.nodes,
    categoryDescriptions: response?.category?.categoryDescriptions?.nodes,
  }
}

export async function allLocationsAllCategories() {
  const lng = i18next.language === "de" ? "de" : "en"
  const query = `{
      category(id: ${staticContent[lng].databaseId}, idType: DATABASE_ID) {
        categoryDescriptions:posts(where: { categoryNotIn: [${locationCategoryIdArray[lng]}]}) {
          nodes {
             ...PostValues
          }
        }
      }
    }${postFragment}`

  const response = await request(baseUrl, query)
  //TODO remove location description from category description, and have it as the location description
  return {
    locationDescriptions: response?.category?.locationDescriptions?.nodes,
    categoryDescriptions: response?.category?.categoryDescriptions?.nodes,
  }
}
