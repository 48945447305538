import {
  FrontPageQueryResult,
  HeaderQueryRes,
  JobHistoryPost,
  FetchCategoriesAndLocationsResult,
  LocationType,
  CategoryType,
  FetchCategoryDescriptionsResults,
  PageResultType,
  PostsPageQueryResult,
} from "./types"
import i18next from "i18next"
import { gql, request } from "graphql-request"
import {
  allLocationsAllCategories,
  allLocationsOneCategory,
  oneLocationsAllCategories,
  oneLocationsOneCategory,
} from "./categoryMatrixApiCalls"

import headerCall from "../utils/tempHardcodeResponses/headerCall.json"
import locsAndHeaders from "../utils/tempHardcodeResponses/locatinosAndCategories.json"

export const baseUrl = "https://api.rootsforlife.info/graphql"
// export const baseUrl = "http://18.194.237.215/graphql"
export const baseImageUrl = "https://api.rootsforlife.info"

export const staticContent = {
  en: {
    databaseId: [279], //277 is the german and 279 the english
  },
  de: {
    databaseId: [277], //277 is the german and 279 the english
  },
}
export const categoryIdArray = { de: [225, 227, 218, 214], en: [223, 229, 221, 216] }
export const locationCategoryIdArray = {
  de: [233, 235, 241, 257, 259, 265, 267],
  en: [231, 237, 239, 255, 261, 263, 267], //267 Other location is missing for ENG
}

export const TOPNEWS = {
  en: 285,
  de: 287,
}
export const NEWS = {
  en: 281,
  de: 283,
}

export async function fetchFrontPageQuery(): Promise<FrontPageQueryResult> {
  const lng = i18next.language === "de" ? "de" : "en"
  const query = gql`
    {
      page(id: "/", idType: URI) {
        translation(language: ${i18next.t("api:DE")}) {
          id
          content
          title
          uri
          featuredImageId
          featuredImageDatabaseId
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
      posts(first: 10, where: { categoryId: ${TOPNEWS[lng]} }) {
        nodes {
          translation(language: ${i18next.t("api:DE")}) {
            featuredImage {
              node {
                sourceUrl
                uri
              }
            }
            excerpt
            title
            uri
            date
            language {
              code
            }
            categories(where: {exclude: [283,285,281,287]}) {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  `
  const response = await request(baseUrl, query)
  return response
}

type fetchPostsPageQueryProps = {
  after?: string
  before?: string
}

export async function fetchPostsPageQuery({
  after = "",
  before = "",
}: fetchPostsPageQueryProps): Promise<PostsPageQueryResult> {
  const lng = i18next.language === "de" ? "de" : "en"
  const query = gql`
    {
      page(id: "/aktuelles", idType: URI) {
        translation(language: ${i18next.t("api:DE")}) {
          id
          content
          title
          uri
          featuredImageId
          featuredImageDatabaseId
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
      posts(first: 10,after:"${after}",before:"${before}", where: { categoryIn: [${NEWS[lng]}] }) {
        nodes {
          translation(language: ${i18next.t("api:DE")}) {
            featuredImage {
              node {
                sourceUrl
                uri
              }
            }
            excerpt
            title
            uri
            date
            language {
              code
            }
            categories(where: {exclude: [283,285,281,287]}) {
              nodes {
                name
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  `
  const response = await request(baseUrl, query)
  return response
}

export async function fetchHeaderPages(): Promise<HeaderQueryRes[]> {
  const lng = i18next.language === "de" ? "de" : "en"
  if (true) {
    return headerCall[lng].data.pages?.nodes
  }

  const query = gql`
    {
      pages(where: { language: ${i18next.t("api:DE")} }) {
        nodes {
          title
          uri
          showPageWhere {
            showpagewhere
            lefttorightorder
          }
          postCarousellCategory {
            orderByValue: ordervalue
          }
        }
      }
    }
  `
  const response = await request(baseUrl, query)
  return response?.pages?.nodes
}

const LOCATION = {
  de: 273,
  en: 271,
}

const PROJECTS = {
  de: 329,
  en: 331,
}

export async function fetchCategoriesAndLocations(): Promise<FetchCategoriesAndLocationsResult> {
  const lng = i18next.language === "de" ? "de" : "en"

  if (true) {
    // return locsAndHeaders
    return {
      locations: locsAndHeaders[lng].data.locations?.translation?.children?.locations,
      categories: locsAndHeaders[lng].data.categories?.translation?.children?.nodes,
    }
  }

  const query = gql`
    {
      locations: category(id: ${LOCATION[lng]}, idType: DATABASE_ID) {
        translation(language: ${i18next.t("api:DE")}) {
          id
          uri
          name
          children {
            locations: nodes {
              name
              id
              databaseId
            }
          }
        }
      }
      categories: category(id: ${PROJECTS[lng]}, idType: DATABASE_ID) {
        translation(language: ${i18next.t("api:DE")}) {
          children {
            nodes {
              name
              id
              description
              databaseId
            }
          }
        }
      }
    }
  `
  // ` const query = gql`
  //   {
  //     locations: category(id: "dGVybToyNzM=") {
  //       id
  //       uri
  //       name
  //       children {
  //         locations: nodes {
  //           name
  //           id
  //           databaseId
  //         }
  //       }
  //     }
  //     categories: category(id: "dGVybTozMjk=") {
  //       children {
  //         nodes {
  //           name
  //           id
  //           description
  //           databaseId
  //         }
  //       }
  //     }
  //   }
  // `
  const response = await request(baseUrl, query)
  return {
    locations: response?.locations?.translation?.children?.locations,
    categories: response?.categories?.translation?.children?.nodes,
  }
}

function restructureResponse(response: any) {
  let categoryDescriptions = Array.from<any>(response?.categoryDescriptions)

  const locationContent = categoryDescriptions?.splice(
    categoryDescriptions
      ?.map(({ databaseId }) => databaseId)
      ?.indexOf(response?.locationDescriptions?.[0].databaseId),
    1,
  )

  return {
    categoryDescriptions,
    posts: response.posts,
    locationDescriptions: locationContent,
  }
}

export async function fetchCategoryDescriptions(
  categories: CategoryType[],
  location?: LocationType,
): Promise<FetchCategoryDescriptionsResults> {
  let response
  // if (true) {
  if (!location && categories?.length === 1) {
    response = await allLocationsOneCategory(categories)
    response = restructureResponse({
      locationDescriptions: response.categoryDescriptions,
      categoryDescriptions: response.locationDescriptions,
      posts: response.posts,
    })
  } else if (location && categories?.length === 1) {
    response = await oneLocationsOneCategory(categories, location)
    response = restructureResponse(response)
  } else if (location && categories.length > 1) {
    //TODO case location & many categories}

    response = await oneLocationsAllCategories(categories, location)
    response = restructureResponse(response)
  } else {
    //TODO add locationcontext, or redux to keep track of location (category)Id's
    response = await allLocationsAllCategories()
  }
  //
  // const categoryDescriptions = Array.from<any>(response?.categoryDescriptions)
  // const locationContent = response?.locationDescriptions?.splice(
  //   categoryDescriptions?.indexOf({
  //     databaseId: response?.locationDescriptions?.[0].databaseId,
  //   }),
  //   1,
  // )
  // console.log("response?.category?.categoryDescription?.nodes")
  // console.log(response?.categoryDescriptions)
  // console.log(locationContent)

  return response
}

export async function fetchPageQuery(uri: string): Promise<PageResultType> {
  const query = gql`
    {
      pageBy(uri: "${uri}") {
       translation(language: ${i18next.t("api:DE")}) {
        id
        content(format: RENDERED)
        title
        uri
        featuredImageId
        featuredImageDatabaseId
        featuredImage{
          node{
            sourceUrl
          }
        }
         postCarousellCategory {
          postCarousellCategory : postcarousellcategory 
        }
        translation(language: ${i18next.t("api:OTHER")}) {
        uri
      }
      }
      }
    }
  `
  const response = await request(baseUrl, query)
  return response?.pageBy?.translation
}

export async function fetchPostQuery(uri: string): Promise<PageResultType> {
  const query = gql`
    {
      post(idType: URI, id: "${uri}") {
        id
        date
        content(format: RENDERED)
        title
        uri
        excerpt
        featuredImageId
        featuredImageDatabaseId
        featuredImage {
          node {
            sourceUrl
          }
        }
         translation(language: ${i18next.t("api:OTHER")}) {
        uri
      }
      }
    }
  `
  const response = await request(baseUrl, query)
  return response?.post
}

export async function fetchJobHistoryPosts(tags = "jobHistory"): Promise<JobHistoryPost[]> {
  const query = gql`
    {
      posts(where: { categoryName: "jobHistory" }) {
        nodes {
          title
          history {
            description
            fieldGroupName
            from
            to
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `
  const response = await request(baseUrl, query)
  return response?.posts?.nodes
}

export function fullImageUrl(uri: string): string {
  return baseImageUrl + uri
}
