import React, { useEffect, useState } from "react"
import { fetchFrontPageQuery } from "../../utils/apiCalls"
import "./Home.css"
import { LatestPostsType, PageResultType } from "../../utils/types"
import { ContentSection } from "../Page/Page"
// import { DisplayTopic } from "../ProjectsOverviewPage/ProjectsDisplayer"
// import { BLOGPOST_BASE_URL } from "../../utils/routesConfig"
// import { useNavigate } from "react-router"
import styles from "../Page/Page.module.css"
import { useTranslation } from "react-i18next"
import BlogPostCarousell from "../../components/BlogPostCarousell"

function Home(): JSX.Element {
  // const frontPageQueryResults = []
  const [isLoading, setLoading] = useState<boolean | string>()
  const [wpPage, setWPPage] = useState<PageResultType>()
  const [latestPosts, setLatestPosts] = useState<LatestPostsType>()
  // const navigate = useNavigate()
  const { i18n } = useTranslation()

  useEffect(() => {
    try {
      setLoading(true)
      fetchFrontPageQuery().then((res) => {
        if (res) {
          setWPPage(res?.page?.translation)
          setLatestPosts(res?.posts)
          setLoading("DONE")
        } else {
          setLoading("error")
        }
      })
    } catch (e) {
      // setLoading('error')
    }
    // return () => ac.abort()
    // }, [])
  }, [i18n.language])

  return isLoading === "error" ? (
    <></>
  ) : (
    <>
      <ContentSection pathname={"/"} wpPage={wpPage} />
      {Array.isArray(latestPosts?.nodes) && (
        <>
          <div className={styles.divider} />
          {/*<div className={styles.titleWrapper}>*/}
          {/*  {wpPage && <h1>{t("Aktuelle Nachrichten")}</h1>}*/}
          {/*</div>*/}
        </>
      )}
      {isLoading === "DONE" && Array.isArray(latestPosts?.nodes) && (
        <>{<BlogPostCarousell propPosts={latestPosts?.nodes.map((p) => p.translation)} />}</>
      )}

      {/*{Array.isArray(latestPosts?.nodes) &&*/}
      {/*  latestPosts?.nodes.map((postObj: PostsType) => {*/}
      {/*    const post = postObj?.translation*/}
      {/*    return (*/}
      {/*      <div key={post?.uri} onClick={() => navigate(BLOGPOST_BASE_URL + post?.uri)}>*/}
      {/*        <DisplayTopic*/}
      {/*          key={post.title}*/}
      {/*          topic={{*/}
      {/*            content: post.excerpt,*/}
      {/*            title: post.title,*/}
      {/*            featuredImage: post?.featuredImage,*/}
      {/*          }}*/}
      {/*          date={post.date}*/}
      {/*          onClick={() => navigate(BLOGPOST_BASE_URL + post?.uri)}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  })}*/}
    </>
  )
}

export default Home
