import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import BaseLayout from "./BaseLayout/BaseLayout"
import "semantic-ui-css/semantic.min.css"
import Home from "./pages/Home/Home"
import { HistoryContextProvider } from "./utils/HistoryContext"
import * as serviceWorker from "./utils/serviceWorker"
import { config } from "./utils/routesConfig"
import "./index.css"
import i18n from "i18next"
import Page from "./pages/Page/Page"
import ProjectsOverviewPage from "./pages/ProjectsOverviewPage/ProjectsOverviewPage"
import BlogPost from "./pages/BlogPost/BlogPost"
import langEn from "./translations/langEn"
import langDe from "./translations/langDe"
import { TranslationLinkToContextProvider } from "./components/TranslationLinkToContextProvider"
import StartLanguageListener from "./components/StartLanguageListener"
import Posts from "./pages/Posts/Posts"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    resources: {
      en: langEn,
      de: langDe,
    },
    lng: "de", // if you're using a language detector, do not define the lng option
    fallbackLng: "de",
    interpolation: {
      escapeValue: true,
    },
    // react: {
    //   bindI18n: "loaded languageChanged",
    //   bindI18nStore: "added",
    //   useSuspense: true,
    // },
  })

export function App(): JSX.Element {
  return (
    <HistoryContextProvider>
      <>
        <Router>
          <StartLanguageListener />
          <TranslationLinkToContextProvider>
            <BaseLayout>
              <Routes>
                <Route path={config.routes.home} element={<Home />} />
                <Route path={config.routes.page} element={<Page />} />
                <Route path={config.routes.pageEn} element={<Page />} />
                <Route path={config.routes.posts} element={<Posts />} />
                <Route path={config.routes.postsEn} element={<Posts />} />
                <Route path={config.routes.projectPage} element={<ProjectsOverviewPage />} />
                <Route path={config.routes.projectPageCat} element={<ProjectsOverviewPage />} />
                <Route path={config.routes.projectPageBase} element={<ProjectsOverviewPage />} />
                <Route
                  path={config.routes.projectPageEn}
                  element={<ProjectsOverviewPage lang={"en"} />}
                />
                <Route
                  path={config.routes.projectPageCatEn}
                  element={<ProjectsOverviewPage lang={"en"} />}
                />
                <Route
                  path={config.routes.projectPageBaseEn}
                  element={<ProjectsOverviewPage lang={"en"} />}
                />
                <Route path={config.routes.blogPost} element={<BlogPost />} />
                <Route path={config.routes.blogPostEng} element={<BlogPost />} />
              </Routes>
            </BaseLayout>
          </TranslationLinkToContextProvider>
        </Router>
      </>
    </HistoryContextProvider>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// const LanguageListener
