export default {
  footerLinks: {
    "Kontakt & Impressum": "Contact & Imprint",
    "/kontakt-impressum": "/kontakt-impressum",
    Netzwerk: "Network",
    "/netzwerk": "/netzwerk",
    Downloads: "Newsletter & Downloads",
    "/downloads": "/downloads",
  },
  translation: {
    "Welcome to React": "Welcome to React and react-i18next",
    "Aktuelle Nachrichten": "Latest News",
  },
  api: {
    stuff: "stuff",
    DE: "EN",
    OTHER: "DE",
  },
  projectOverview: {
    all: "All",
    matrixClickUrl: "/en/projects/",
    minimize: "...read less",
    readMore: "... read more",
    locations: "Locations",
    projectType: "Project themes",
    toTopOfScreen: "To the top",
  },
}
