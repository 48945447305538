import styles from "../pages/Page/Page.module.css"
import { ReactComponent as Wave } from "../assets/wave.svg"
import React from "react"
import { PageResultType } from "../utils/types"

export const HeroImage = ({ wpPage }: { wpPage?: PageResultType }) => {
  return (
    <div className={styles["hero-image-container"]}>
      <div
        className={styles.heroImage}
        style={
          wpPage?.featuredImage?.node?.sourceUrl
            ? {
                backgroundImage: `linear-gradient(to right, rgba(249, 243, 225, 1) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(249, 243, 225, 1) 100%),url("${wpPage?.featuredImage?.node?.sourceUrl}")`,
              }
            : {}
        }
      >
        <img alt={""} className={styles.heroImgImg} src={wpPage?.featuredImage?.node?.sourceUrl} />
      </div>
      <div className={styles.waveContainer}>
        <Wave />
      </div>
    </div>
  )
}
