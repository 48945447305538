import React, { useEffect } from "react"
import { useLocation } from "react-router"
import { useTranslation } from "react-i18next"

function StartLanguageListener() {
  const { pathname } = useLocation()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (pathname.includes("/en/")) {
      i18n.changeLanguage("en")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <></>
}

export default StartLanguageListener
