import React, { useState } from "react"

export const TranslationLinkToContext = React.createContext({
  linkTo: "",
  setLinkTo: (s: string) => {},
})

export function TranslationLinkToContextProvider({ children }: { children: any }) {
  const [linkTo, setLinkTo] = useState<string>("")

  return (
    <TranslationLinkToContext.Provider value={{ linkTo, setLinkTo }}>
      {children}
    </TranslationLinkToContext.Provider>
  )
}

export default TranslationLinkToContext
