import React, { RefObject, useContext, useEffect, useState } from "react"
import { FooterLinksType, HeaderQueryRes } from "../utils/types"
import { fetchHeaderPages } from "../utils/apiCalls"
import styles from "./BaseLayout.module.css"
import MediaQuery from "react-responsive"
import { Link, NavLink, useNavigate } from "react-router-dom"
import image from "../assets/roots_for_life_TEMP_logo_long.png"
import classNames from "classnames"
import { Button, Icon, Menu, Portal, Segment, Sidebar } from "semantic-ui-react"
import { sortByValueFunction } from "../utils/helperFunctions"
import Flag from "semantic-ui-react/dist/commonjs/elements/Flag"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import TranslationLinkToContext from "../components/TranslationLinkToContextProvider"

const linkActiveStyle = {
  textShadow: "0.5px 0px black",
  transition: "text-shadow 0.5s",
}
export const BaseLayoutHeader = ({
  mainContainerRef,
  footerLinksObject,
}: {
  mainContainerRef: RefObject<any>
  footerLinksObject: FooterLinksType[]
}) => {
  const [headerPages, setHeaderPages] = useState<HeaderQueryRes[]>([])
  const { i18n } = useTranslation()

  useEffect(() => {
    try {
      // if (headerPages.length === 0) {
      // setLoading(true)
      fetchHeaderPages().then((res) => {
        if (res) {
          setHeaderPages(res)
        } else {
          // setLoading('error')
        }
      })
      // }
    } catch (e) {
      // setLoading('error')
    }
  }, [i18n.language])

  return (
    <div className={styles["base-header"]}>
      <MediaQuery minWidth={1024}>
        <div className={styles["base-inner-header"]}>
          <div className={styles["link-container"]}>
            {footerLinksObject.map((linkObj, index) => (
              <Link key={index} className={styles.baseLayoutLinks} to={linkObj.to}>
                {linkObj.text}
              </Link>
            ))}
            <div className={styles.flagContainer + " " + styles.fbButtonContainer}>
              <FacebookButton />
            </div>

            <div className={styles.flagContainer}>
              <LanguageButton name={"us"} />
              <LanguageButton name={"de"} />
            </div>
            {/*<Flag name={"de"} onClick={() => changeLang("de")} />*/}
          </div>
        </div>
      </MediaQuery>
      <div className={styles.lowerHeader}>
        <div className={styles.logoContainer}>
          <Link to={"/"}>
            <img className={styles.logo} src={image} alt={""} />
          </Link>
        </div>
        <MediaQuery minWidth={800}>
          <div className={styles["lower-link-container"]}>
            {headerPages
              .sort((a, b) =>
                sortByValueFunction(
                  a.showPageWhere.lefttorightorder,
                  b.showPageWhere.lefttorightorder,
                ),
              )
              .map(
                (page, index) =>
                  page.showPageWhere?.showpagewhere[0] === "showInPagesMenu" && (
                    <NavLink
                      end
                      key={index}
                      className={classNames(styles.baseLayoutLinks, styles.fadeInnLinks)}
                      activeStyle={linkActiveStyle}
                      to={{ pathname: page.uri }}
                    >
                      <h3>{page.title}</h3>
                    </NavLink>
                  ),
              )}
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={1024}>
          <SideBarPortal
            mainContainerRef={mainContainerRef}
            headerPages={headerPages}
            footerLinksObject={footerLinksObject}
          />
        </MediaQuery>
      </div>
    </div>
  )
}

export const FacebookButton = () => {
  return (
    <a
      href={"https://www.facebook.com/Roots-for-Life-203593443159822/"}
      rel="noopener noreferrer"
      target={"_blank"}
      style={{ color: "white" }}
    >
      {
        // @ts-ignore
        <Icon size={"large"} name={"facebook"} />
      }
    </a>
  )
}

export const LanguageButton = ({ name }: { name: string }) => {
  const { i18n } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { linkTo } = useContext(TranslationLinkToContext)

  const changeLang = (lng: string) => {
    i18n.changeLanguage(lng)
    if (pathname === "/") {
    } else if (pathname.includes("/projekte") || pathname.includes("/en/projects")) {
      if (lng === "de") {
        navigate("/projekte")
      } else {
        navigate("/en/projects")
      }
    } else {
      navigate(linkTo)
    }
  }
  return (
    <div>
      <Flag
        name={name === "de" ? "de" : "us"}
        onClick={() => changeLang(name === "de" ? "de" : "en")}
      />
    </div>
  )
}

const SideBarPortal = ({
  mainContainerRef,
  headerPages,
  footerLinksObject,
}: {
  mainContainerRef: RefObject<any>
  footerLinksObject: FooterLinksType[]
  headerPages: HeaderQueryRes[]
}) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false)

  return (
    <div className={styles["lower-link-container-mobile"]}>
      <Portal
        open={mainContainerRef.current && true}
        mountNode={mainContainerRef.current}
        closeOnTriggerClick
        openOnTriggerClick
        trigger={
          <Button
            icon={"bars"}
            style={{ float: "right", backgroundColor: showSidebar && "green" }}
            onClick={() => setShowSidebar(!showSidebar)}
          />
        }
      >
        <Sidebar.Pushable
          as={Segment}
          style={{
            position: "relative",
            zIndex: 10,
            overflowY: "hidden",
            overflowX: "visible",
            border: "none",
            maxWidth: "89px",
            minWidth: "150px",
            maxHeight: "100vh",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            inverted
            onHide={() => setShowSidebar(false)}
            vertical
            visible={showSidebar}
            width="thin"
          >
            {headerPages.map((page, index) => (
              <Menu.Item key={index}>
                <NavLink
                  end
                  key={page.uri}
                  activeStyle={linkActiveStyle}
                  to={{ pathname: page.uri }}
                >
                  <h3>{page.title}</h3>
                </NavLink>
              </Menu.Item>
            ))}
            <MediaQuery maxWidth={1000}>
              {/*{footerLinksObject.map((page, index) => (*/}
              {/*  <Menu.Item key={index + "footer"}>*/}
              {/*    <NavLink*/}
              {/*      end*/}
              {/*      key={page.to}*/}
              {/*      activeStyle={linkActiveStyle}*/}
              {/*      to={{ pathname: page.to }}*/}
              {/*    >*/}
              {/*      <h3>{page.text}</h3>*/}
              {/*    </NavLink>*/}
              {/*  </Menu.Item>*/}
              {/*))}*/}
              <Menu.Item key={"flags"}>
                <div className={styles.flagContainerSideMenu} style={{ margin: "auto" }}>
                  <LanguageButton name={"us"} />
                  <LanguageButton name={"de"} />
                </div>
              </Menu.Item>
              <Menu.Item key={"fb"}>
                <FacebookButton />
              </Menu.Item>
            </MediaQuery>
          </Sidebar>

          <Sidebar.Pusher
            style={{
              position: "relative",
              zIndex: 10,
              maxWidth: "10vw",
            }}
          >
            <Segment basic />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Portal>
    </div>
  )
}
